import appSession from './AppSession';
import Auth from './Auth';
import Me from './Me';
import Users from './Users';
import Events from './Events';
import AdEvents from './AdEvents';
import Articles from './Articles';
import Pages from './Pages';
import Venues from './Venues';
import Invites from './Invites';
import InterestTopics from './InterestTopics';
import TicketOptions from './TicketOptions';
import TicketInventory from './TicketInventory';
import Orders from './Orders';
import Payment from './Payment';
import Info from './Info';
import Cart from './Cart';
import Sync from './Sync';
import Tickets from './Tickets';
import OpeningHours from './OpeningHours';
import Notifications from './Notifications';
import MetaData from './MetaData';
import Feature from './Feature';
import Property from './Property';
import Credentials from './Credentials';
import Announcements from './Announcements';
import Subscriptions from './Subscriptions';
import Discounts from './Discounts';
import Checkout from './Checkout';
import Menu from './Menu';
import Bundles from './Bundles';
import Deals from './Deals';
import ExternalBookings from './ExternalBookings';
import OrderUpdate from './OrderUpdate';
import Addons from './Addons';
import BetaFeatures from './BetaFeatures';
import EventCategories from './EventCategories';
import Refunds from './Refunds';
import Birthdays from './Birthdays';
import Contact from './Contact';
import Salesforce from './Salesforce';
import Search from './Search';
import Shopify from './Shopify';
import ShopifyVendors from './ShopifyVendors';
import ExternalObjectsStore from './ExternalObjects';
import DreamPayStore from './DreamPay';
import SskReaderStore from './SskReader';
import ExternalOperatorsStore from './ExternalOperators';
import DataCacheStore from './DataCache';

export default {
  appSession,
  me: new Me(),
  auth: new Auth(),
  cart: new Cart(),
  sync: new Sync(),
  info: new Info(),
  events: new Events(),
  adEvents: new AdEvents(),
  feature: new Feature(),
  articles: new Articles(),
  pages: new Pages(),
  venues: new Venues(),
  users: new Users(),
  invites: new Invites(),
  interestTopics: new InterestTopics(),
  ticketOptions: new TicketOptions(),
  ticketInventory: new TicketInventory(),
  orders: new Orders(),
  payment: new Payment(),
  tickets: new Tickets(),
  openingHours: new OpeningHours(),
  metaData: new MetaData(),
  notifications: new Notifications(),
  property: new Property(),
  credentials: new Credentials(),
  announcements: new Announcements(),
  subscriptions: new Subscriptions(),
  discounts: new Discounts(),
  checkout: new Checkout(),
  menu: new Menu(),
  bundles: new Bundles(),
  deals: new Deals(),
  externalbookings: new ExternalBookings(),
  orderUpdate: new OrderUpdate(),
  addons: new Addons(),
  betaFeatures: new BetaFeatures(),
  eventcategories: new EventCategories(),
  refunds: new Refunds(),
  birthdays: new Birthdays(),
  contact: new Contact(),
  salesforce: new Salesforce(),
  search: new Search(),
  shopify: new Shopify(),
  shopifyvendors: new ShopifyVendors(),
  externalObjects: new ExternalObjectsStore(),
  dreamPay: new DreamPayStore(),
  sskReader: new SskReaderStore(),
  externaloperators: new ExternalOperatorsStore(),
  datacache: new DataCacheStore(),
};
