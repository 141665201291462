import React from 'react';
import { Loader, Table, Checkbox, Button } from 'common/lazy';
import { loadPreferences } from '@segment/consent-manager';
import { Component } from 'common/helpers';

import { Spacer } from '../Spacer';

import { DESTINATION_GROUPS } from './const';

import saveGroupConsent from './saveGroupConsent';
import fetchDestinations from './fetchDestinations';
import getInitialPreferences from './getInitialPreferences';

import './dialog.less';

const GROUPS = {
  functional: {
    title: 'Functional',
    description: [
      'To monitor the performance of our site and to enhance your browsing experience.',
      'For example, these tools enable you to communicate with us via live chat.',
    ],
  },
  marketingAndAnalytics: {
    title: 'Marketing and Analytics',
    categories: ['Analytics'],
    description: [
      'To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site.',
      'For example, we collect information about which pages you visit to help us present more relevant information.',
    ],
  },
  advertising: {
    title: 'Advertising',
    description: [
      'To personalize and measure the effectiveness of advertising on our site and other websites.',
      'For example, we may serve you a personalized ad based on the pages you visit on our site.',
    ],
  },
  essential: {
    title: 'Essential',
    description: [
      'American Dream uses browser cookies that are necessary for the site to work as intended.',
      'For example, we store your website data collection preferences so we can honor them when you return to our site.',
      'You can disable these cookies in your browser settings but if you do the site may not work as intended.',
    ],
  },
};

export default class ConsentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      destinations: null,
      groupPrefs: this.getInitialGroupPrefs(),
    };
  }

  getInitialGroupPrefs() {
    const initial = getInitialPreferences();
    const loaded = loadPreferences().customPreferences;
    return Object.assign({}, initial, loaded);
  }

  componentDidMount() {
    this.fetchDestinations();
  }

  async fetchDestinations() {
    const destinations = await fetchDestinations();
    this.setState({
      destinations,
    });
  }

  setField = (evt) => {
    this.setState({
      touched: true,
      groupPrefs: Object.assign(this.state.groupPrefs, {
        [evt.target.name]: evt.target.checked,
      }),
    });
  };

  save = () => {
    const { groupPrefs, destinations } = this.state;
    saveGroupConsent(groupPrefs, destinations, true);
    this.setState({
      touched: false,
    });
    this.props.onSave();
  };

  render() {
    const { destinations, touched } = this.state;
    if (!destinations) {
      return (
        <React.Fragment>
          <Spacer size="l" />
          <Loader inline="centered" active />
          <Spacer size="l" />
        </React.Fragment>
      );
    }
    return (
      <div {...this.getAttrs()}>
        <p>
          We use data collected by cookies and JavaScript libraries to improve
          your browsing experience, analyze site traffic, deliver personalized
          advertisements, and increase the overall performance of our site.
        </p>
        <Spacer size="s" />
        <Table basic striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>Allow</Table.HeaderCell>
              <Table.HeaderCell singleLine>Category</Table.HeaderCell>
              <Table.HeaderCell singleLine>Purpose</Table.HeaderCell>
              <Table.HeaderCell singleLine>Tools</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderGroups()}</Table.Body>
        </Table>
        <Spacer size="s" />
        <div className={this.getElementClass('actions')}>
          {this.props.showCancel && (
            <Button onClick={this.props.onCancel}>Cancel</Button>
          )}
          <Button onClick={this.save} disabled={!touched} primary>
            Save
          </Button>
        </div>
      </div>
    );
  }

  renderGroups() {
    return Object.keys(GROUPS).map((id) => this.renderGroup(id));
  }

  renderGroup(id) {
    const { destinations } = this.state;
    const { title, description } = GROUPS[id];
    const groupDestinations = destinations.filter((destination) => {
      return DESTINATION_GROUPS[destination.name] === id;
    });
    return (
      <Table.Row key={id}>
        <Table.Cell textAlign="center">
          {id === 'essential' ? (
            <span>N/A</span>
          ) : (
            <Checkbox
              id={id}
              name={id}
              checked={this.state.groupPrefs[id]}
              onChange={this.setField}
              toggle
            />
          )}
        </Table.Cell>
        <Table.Cell className={this.getElementClass('category')}>
          <label htmlFor={id}>{title}</label>
        </Table.Cell>
        <Table.Cell>
          {description.map((d, i) => (
            <p key={i}>{d}</p>
          ))}
        </Table.Cell>
        <Table.Cell className={this.getElementClass('destinations')}>
          {groupDestinations.map((d) => d.name).join(', ')}
        </Table.Cell>
      </Table.Row>
    );
  }
}
