import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'common/lazy';
import { createPortal } from 'react-dom';

import { useClassName } from 'common/hooks';
import { track } from 'utils/analytics';
import { SVGIcon } from 'common/components';

import HomepageImage from 'public/screens/Home/Components/Image';
import useCustomStyle from '../../hooks';

import './link.less';

export default function Link({ component, children }) {
  const classNames = useClassName('CLPLink');
  const [setRef] = useCustomStyle(component.fields.settings);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    if (component.fields.isPdf) {
      setIsOpen(true);
    } else {
      if (component.fields.type === 'external') {
        window.open(component.fields.url, '_blank');
      } else {
        history.push(component.fields.url);
      }
    }

    track('Redirect', { url: component.fields.url, origin: location.pathname });
    e.preventDefault();
  };

  return (
    <>
      {component.fields.isPdf && (
        <Modal
          onClose={() => setIsOpen(false)}
          className={classNames('modal')}
          open={isOpen}>
          {createPortal(
            <div
              className={classNames('close-button')}
              onClick={() => setIsOpen(false)}>
              <SVGIcon name="close" size="tiny" />
            </div>,
            document.getElementsByTagName('body')[0]
          )}
          <object
            data={component.fields.url}
            type="application/pdf"
            width="100%"
            height="100%"
            style={{
              height: '80vh',
            }}>
            <p>
              <a href={component.fields.url}>Click here to open the menu</a>
            </p>
          </object>
        </Modal>
      )}
      {children ? (
        <a href={component.fields.url} onClick={onClick}>
          {children}
        </a>
      ) : (
        <a
          href={component.fields.url}
          ref={setRef}
          onClick={onClick}
          className={classNames([
            component.fields.theme !== 'other' && 'container',
            component.fields.theme,
          ])}>
          {component.fields.label}
          {Boolean(component.fields.icon) && (
            <HomepageImage field={component.fields.icon} />
          )}
        </a>
      )}
    </>
  );
}
