import React from 'react';
import { Component } from 'common/helpers';
import { Modal, Image } from 'common/lazy';
import { ExternalLink, SVGIcon as Icon } from 'common/components';
import { track } from 'utils/analytics';
import './social-links.less';

const LINKS = [
  {
    icon: 'tiktok',
    url: 'https://www.tiktok.com/@americandream_official?lang=en',
  },
  {
    icon: 'instagram',
    url: 'https://www.instagram.com/americandream',
  },
  {
    icon: 'facebook',
    url: 'https://www.facebook.com/americandreamofficial/',
  },
  {
    icon: 'twitter',
    url: 'https://twitter.com/americandream',
  },
  {
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/americandream',
  },
];

export default class SocialLinks extends Component {
  render() {
    return <span {...this.getAttrs()}>{this.renderLinks()}</span>;
  }

  handleLinkPress = (name) => {
    return () => {
      track(`Footer - Viewed ${name}`);
    };
  };

  renderLinks() {
    return LINKS.map((item) => {
      if (item.url) {
        return (
          <ExternalLink
            key={item.icon}
            href={item.url}
            className={this.getElementClass('link')}
            onClick={this.handleLinkPress(item.icon)}>
            <Icon name={item.icon} size="small" />
          </ExternalLink>
        );
      } else {
        return (
          <Modal
            key={item.icon}
            className={this.getElementClass('modal')}
            trigger={
              <div
                className={this.getElementClass('link')}
                onClick={this.handleLinkPress(item.icon)}>
                <Icon name={item.icon} size="small" />
              </div>
            }>
            <Image
              className={this.getElementClass('modal-image')}
              src={item.image}
            />
          </Modal>
        );
      }
    });
  }
}
