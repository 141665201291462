import { lazily } from 'react-lazily';

const {
  Container: SemanticContainer,
  Dropdown: SemanticDropdown,
  DropdownDivider: SemanticDropdownDivider,
  DropdownHeader: SemanticDropdownHeader,
  DropdownItem: SemanticDropdownItem,
  DropdownMenu: SemanticDropdownMenu,
  DropdownSearchInput: SemanticDropdownSearchInput,
  Icon: SemanticIcon,
  IconGroup: SemanticIconGroup,
  Menu: SemanticMenu,
  MenuMenu: SemanticMenuMenu,
  MenuItem: SemanticMenuItem,
  Message: SemanticMessage,
  MessageContent: SemanticMessageContent,
  MessageHeader: SemanticMessageHeader,
  MessageItem: SemanticMessageItem,
  MessageList: SemanticMessageList,
  Label: SemanticLabel,
  LabelDetail: SemanticLabelDetail,
  LabelGroup: SemanticLabelGroup,
  Form: SemanticForm,
  FormGroup: SemanticFormGroup,
  FormButton: SemanticFormButton,
  FormCheckbox: SemanticFormCheckbox,
  FormDropdown: SemanticFormDropdown,
  FormField: SemanticFormField,
  FormInput: SemanticFormInput,
  FormRadio: SemanticFormRadio,
  FormSelect: SemanticFormSelect,
  FormTextArea: SemanticFormTextArea,
  Grid: SemanticGrid,
  GridColumn: SemanticGridColumn,
  GridRow: SemanticGridRow,
  Table: SemanticTable,
  TableBody: SemanticTableBody,
  TableCell: SemanticTableCell,
  TableFooter: SemanticTableFooter,
  TableHeader: SemanticTableHeader,
  TableHeaderCell: SemanticTableHeaderCell,
  TableRow: SemanticTableRow,
  Input: SemanticInput,
  Checkbox: SemanticCheckbox,
  Select: SemanticSelect,
  Popup: SemanticPopup,
  PopupContent: SemanticPopupContent,
  PopupHeader: SemanticPopupHeader,
  Button: SemanticButton,
  ButtonContent: SemanticButtonContent,
  ButtonGroup: SemanticButtonGroup,
  ButtonOr: SemanticButtonOr,
  Header: SemanticHeader,
  HeaderContent: SemanticHeaderContent,
  HeaderSubheader: SemanticHeaderSubheader,
  Modal: SemanticModal,
  ModalActions: SemanticModalActions,
  ModalHeader: SemanticModalHeader,
  ModalContent: SemanticModalContent,
  ModalDescription: SemanticModalDescription,
  Image: SemanticImage,
  ImageGroup: SemanticImageGroup,
  Divider: SemanticDivider,
  Loader: SemanticLoader,
  Dimmer: SemanticDimmer,
  Pagination: SemanticPagination,
  Segment: SemanticSegment,
  SegmentGroup: SemanticSegmentGroup,
  SegmentInline: SemanticSegmentInline,
  List: SemanticList,
  ListContent: SemanticListContent,
  ListDescription: SemanticListDescription,
  ListHeader: SemanticListHeader,
  ListIcon: SemanticListIcon,
  ListItem: SemanticListItem,
  ListList: SemanticListList,
  Card: SemanticCard,
  CardContent: SemanticCardContent,
  CardDescription: SemanticCardDescription,
  CardGroup: SemanticCardGroup,
  CardHeader: SemanticCardHeader,
  CardMeta: SemanticCardMeta,
  Progress: SemanticProgress,
  TextArea: SemanticTextArea,
  Ref: SemanticRef,
  Statistic: SemanticStatistic,
  StatisticGroup: SemanticStatisticGroup,
  StatisticLabel: SemanticStatisticLabel,
  StatisticValue: SemanticStatisticValue,
  Accordion: SemanticAccordion,
  AccordionAccordion: SemanticAccordionAccordion,
  AccordionContent: SemanticAccordionContent,
  AccordionPanel: SemanticAccordionPanel,
  AccordionTitle: SemanticAccordionTitle,
  Radio: SemanticRadio,
  Breadcrumb: SemanticBreadcrumb,
  BreadcrumbSection: SemanticBreadcrumbSection,
  BreadcrumbDivider: SemanticBreadcrumbDivider,
  Flag: SemanticFlag,
  Responsive: SemanticResponsive,
  Step: SemanticStep,
  StepContent: SemanticStepContent,
  StepDescription: SemanticStepDescription,
  StepGroup: SemanticStepGroup,
  StepTitle: SemanticStepTitle,
} = lazily(() => import('semantic-ui-react'));

export const Container = SemanticContainer;
SemanticDropdown.Divider = SemanticDropdownDivider;
SemanticDropdown.Header = SemanticDropdownHeader;
SemanticDropdown.Item = SemanticDropdownItem;
SemanticDropdown.Menu = SemanticDropdownMenu;
SemanticDropdown.SearchInput = SemanticDropdownSearchInput;
export const Dropdown = SemanticDropdown;
SemanticIcon.Group = SemanticIconGroup;
export const Icon = SemanticIcon;
SemanticMenu.Menu = SemanticMenuMenu;
SemanticMenu.Item = SemanticMenuItem;
export const Menu = SemanticMenu;
SemanticMessage.Content = SemanticMessageContent;
SemanticMessage.Header = SemanticMessageHeader;
SemanticMessage.Item = SemanticMessageItem;
SemanticMessage.List = SemanticMessageList;
export const Message = SemanticMessage;
export const MessageContent = SemanticMessageContent;
SemanticLabel.Detail = SemanticLabelDetail;
SemanticLabel.Group = SemanticLabelGroup;
export const Label = SemanticLabel;
SemanticForm.Group = SemanticFormGroup;
SemanticButton.Content = SemanticButtonContent;
SemanticButton.Group = SemanticButtonGroup;
SemanticButton.Or = SemanticButtonOr;
SemanticForm.Button = SemanticFormButton;
SemanticForm.Checkbox = SemanticFormCheckbox;
SemanticForm.Dropdown = SemanticFormDropdown;
SemanticForm.Field = SemanticFormField;
SemanticForm.Input = SemanticFormInput;
SemanticForm.Radio = SemanticFormRadio;
SemanticForm.Select = SemanticFormSelect;
SemanticForm.TextArea = SemanticFormTextArea;
export const Form = SemanticForm;
export const FormGroup = SemanticFormGroup;
SemanticGrid.Column = SemanticGridColumn;
SemanticGrid.Row = SemanticGridRow;
export const Grid = SemanticGrid;
SemanticTable.Body = SemanticTableBody;
SemanticTable.Cell = SemanticTableCell;
SemanticTable.Footer = SemanticTableFooter;
SemanticTable.Header = SemanticTableHeader;
SemanticTable.HeaderCell = SemanticTableHeaderCell;
SemanticTable.Row = SemanticTableRow;
export const Table = SemanticTable;
export const Input = SemanticInput;
export const Checkbox = SemanticCheckbox;
export const Select = SemanticSelect;
SemanticPopup.Content = SemanticPopupContent;
SemanticPopup.Header = SemanticPopupHeader;
export const Popup = SemanticPopup;
export const Button = SemanticButton;
SemanticHeader.Content = SemanticHeaderContent;
SemanticHeader.Subheader = SemanticHeaderSubheader;
export const Header = SemanticHeader;
SemanticModal.Actions = SemanticModalActions;
SemanticModal.Header = SemanticModalHeader;
SemanticModal.Content = SemanticModalContent;
SemanticModal.Description = SemanticModalDescription;
export const Modal = SemanticModal;
export const ModalActions = SemanticModalActions;
export const ModalHeader = SemanticModalHeader;
export const ModalContent = SemanticModalContent;
SemanticImage.Group = SemanticImageGroup;
export const Image = SemanticImage;
export const Divider = SemanticDivider;
export const Loader = SemanticLoader;
export const Dimmer = SemanticDimmer;
export const Pagination = SemanticPagination;
SemanticSegment.Group = SemanticSegmentGroup;
SemanticSegment.Inline = SemanticSegmentInline;
export const Segment = SemanticSegment;
SemanticList.Content = SemanticListContent;
SemanticList.Description = SemanticListDescription;
SemanticList.Header = SemanticListHeader;
SemanticList.Icon = SemanticListIcon;
SemanticList.Item = SemanticListItem;
SemanticList.List = SemanticListList;
export const List = SemanticList;
SemanticCard.Content = SemanticCardContent;
SemanticCard.Description = SemanticCardDescription;
SemanticCard.Group = SemanticCardGroup;
SemanticCard.Header = SemanticCardHeader;
SemanticCard.Meta = SemanticCardMeta;
export const Card = SemanticCard;
export const Progress = SemanticProgress;
export const TextArea = SemanticTextArea;
export const TableCell = SemanticTableCell;
export const TableRow = SemanticTableRow;
export const Ref = SemanticRef;
SemanticStatistic.Group = SemanticStatisticGroup;
SemanticStatistic.Label = SemanticStatisticLabel;
SemanticStatistic.Value = SemanticStatisticValue;
export const Statistic = SemanticStatistic;
SemanticAccordion.Accordion = SemanticAccordionAccordion;
SemanticAccordion.Content = SemanticAccordionContent;
SemanticAccordion.Panel = SemanticAccordionPanel;
SemanticAccordion.Title = SemanticAccordionTitle;
export const Accordion = SemanticAccordion;
export const Radio = SemanticRadio;
SemanticBreadcrumb.Section = SemanticBreadcrumbSection;
SemanticBreadcrumb.Divider = SemanticBreadcrumbDivider;
export const Breadcrumb = SemanticBreadcrumb;
export const Flag = SemanticFlag;
export const Responsive = SemanticResponsive;
SemanticStep.Content = SemanticStepContent;
SemanticStep.Description = SemanticStepDescription;
SemanticStep.Group = SemanticStepGroup;
SemanticStep.Title = SemanticStepTitle;
export const Step = SemanticStep;
